#page-loading {
	position: fixed;
	z-index: 9999 !important;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	display: flex;
	align-items: center;
	justify-content: center;

	background: rgba(1, 7, 38, 1);
}

.lds-facebook,
.lds-facebook div {
	box-sizing: border-box;
}

.lds-facebook {
	position: relative;

	display: inline-block;

	width: 80px;
	height: 80px;
}

.lds-facebook div {
	position: absolute;
	left: 8px;

	display: inline-block;

	width: 16px;

	-webkit-animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
	animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;

	background: #596bc1;
}

.lds-facebook div:nth-child(1) {
	left: 8px;

	-webkit-animation-delay: -0.24s;
	animation-delay: -0.24s;
}

.lds-facebook div:nth-child(2) {
	left: 32px;

	-webkit-animation-delay: -0.12s;
	animation-delay: -0.12s;
}

.lds-facebook div:nth-child(3) {
	left: 56px;

	-webkit-animation-delay: 0s;
	animation-delay: 0s;
}

@-webkit-keyframes lds-facebook {
	0% {
		top: 8px;

		height: 64px;
	}

	50%,
	100% {
		top: 24px;

		height: 32px;
	}
}
@keyframes lds-facebook {
	0% {
		top: 8px;

		height: 64px;
	}

	50%,
	100% {
		top: 24px;

		height: 32px;
	}
}
