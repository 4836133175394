.features-list {
	margin: 0 auto 0;
	padding: 20px 10px 20px 15px;
	list-style: none;
	position: relative;
	border-radius: 12px;
	background-color: $color-dark-900;

	@media (min-width: 768px) {
		margin-bottom: 36px;
		padding: 30px 60px 30px 75px;

		max-width: 700px;
		
	}

	&::before {
		content: '';
		position: absolute;
		top: 50px;
		bottom: 98px;
		left: 35px;
		width: 1px;
		border-radius: 12px;
		background: $color-brand-secondary;
		z-index: 1;

		@media (min-width: 768px) {
			left: 95px
		}
	}

	&__item {
		display: flex;
		position: relative;
		align-items: flex-start;
		justify-content: flex-start;
		gap: 6px;
		z-index: 2;

	@media (min-width: 768px) {
		gap: 30px;
	}
	}

	&__icon {
		padding: 8px;
		width: 24px;
		height: 24px;
		border-radius: 50%;
		border: 1px solid $color-brand-secondary;
		background-color: $color-dark-900;
	}

	&__item:first-child {
		.features-list__icon {
			// margin-top: 10px;
		}
	}

	&__item:last-child {
		margin-top: 22px;
		background-color: $color-dark-900;

		.features-list__description {
			margin-bottom: 0;
		}
	}

	&__title {
		@include gradient-text($gradient-text);
		font-size: 18px;
		
		line-height: 1.45;
		margin: -5px auto 0;
		font-weight: bold;
		letter-spacing: 0.85px;

		

	@media (min-width: 768px) {
		font-size: 20px;
		letter-spacing: 1.2px;
	}
	}

	&__description {
		font-size: 16px;
		line-height: 1.5;
		margin: 0 auto 25px;
		font-weight: normal;
		letter-spacing: 0.7px;
		

	@media (min-width: 768px) {
		font-size: 18px;
		letter-spacing: 1.25px;
	}
	}
}
