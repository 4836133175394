$color-dark-900: #1e1e32;
$color-dark-800: #2d2d41;
$color-brand-primary: #596bc1;
$color-brand-secondary: #94a6f8;

$gradient-text: linear-gradient(90deg, #a1c1ff 12%, #caa1ff 51%, #939dff 99%);
$gradient-text-dark: linear-gradient(to right, #2766df, #4845df, #af27df);
$gradient-border: linear-gradient(90deg, #4900dd 0%, #4c0062 100%);
$gradient-green-prices: linear-gradient(
	90deg,
	#b8ff8d 3%,
	#6ff49c 55%,
	#e4ff3d 99%
);
