.quiz {
	position: fixed;
	z-index: 100;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: #010726;
	height: 100vh;
	max-height: 100vh;
	overflow: hidden;

	.error-message {
		color: #ff3434;
		margin: 0;
		position: absolute;
		top: 12px;
		left: 0;
	}

	.glide__slides {
		height: 100%;
	}

	.glide__slide {
		height: 100%;
	}

	.glide__track {
		height: 100%;
	}

	&__form {
		height: 100%;
	}

	&__screen {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		overflow: hidden;
		padding: 20px 20px 20px;
		height: calc(100% - 40px);
		max-width: 450px;
		margin-left: auto;
		margin-right: auto;
		height: 100%;
		width: 100%;

		min-height: 0;

		@media (min-width: 768px) {
			max-width: 550px;
		}
	}

	&__button-back {
		align-self: flex-start;
		font-size: 0;
		border: none;
		background: url('/images/icon-btn-back.svg') no-repeat center center;
		width: 24px;
		height: 24px;
		cursor: pointer;

		@media (min-width: 768px) {
			width: px;
			height: 36px;
		}
	}

	&__question {
		margin-top: 20px;
		margin-bottom: 0;

		@media (min-width: 768px) {
			font-size: 26px;
		}
	}

	&__options {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		gap: 12px;
		position: relative;
		flex-grow: 1;
		max-height: 500px;
		overflow-y: auto;
		margin: 0 0 20px;
		padding-top: 40px;
		min-height: 0;
		-ms-overflow-style: none;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}
	}

	&-option {
		position: relative;
		border-radius: 12px;
		display: flex;
		flex-direction: column;
		align-items: stretch;
		cursor: pointer;

		&__input[type='radio'] {
			position: absolute;
			left: -9999px;
		}

		&__label {
			padding: 12px 16px;
			background-color: $color-dark-900;
			display: flex;
			border-radius: 12px;
			align-items: center;
			justify-content: flex-start;
			gap: 10px;
			transition: background-color 0.25s ease-in-out;
			font-size: 17px;
			letter-spacing: 0.75px;
			cursor: pointer;

			@media (min-width: 768px) {
				font-size: 17px;
			}

			&::before {
				content: '';
				display: block;
				width: 24px;
				height: 24px;
				border-radius: 50%;
				background: url('/images/icon-radio-not-checked.svg') no-repeat center
					center;
				background-size: contain;
				transition: background 0.25s ease-in-out;

				@media (min-width: 768px) {
					width: 26px;
					height: 26px;
				}
			}

			&-other--filled {
				&::before {
					background: url('/images/icon-radio-checked.svg') no-repeat center
						center !important;
				}
			}
		}

		&__input-textarea,
		&__input-text:not(.quiz-option__input-other) {
			color: #ffffff;
			border-radius: 12px;
			background-color: rgba(255, 255, 255, 0.1);
			border: 1px solid rgba(255, 255, 255, 0.2);
			padding: 12px 19px;
			font-size: 17px;
			outline: none;

			&::-webkit-input-placeholder {
				color: rgba(243, 243, 243, 0.8);
				font-style: italic;
				font-size: 17px;
				font-weight: 300;
			}

			&::-moz-placeholder {
				color: rgba(243, 243, 243, 0.8);
				font-style: italic;
				font-size: 17px;
				font-weight: 300;
			}

			&:-ms-input-placeholder {
				color: rgba(243, 243, 243, 0.8);
				font-style: italic;
				font-size: 17px;
				font-weight: 300;
			}

			&::-ms-input-placeholder {
				color: rgba(243, 243, 243, 0.8);
				font-style: italic;
				font-size: 17px;
				font-weight: 300;
			}

			&::placeholder {
				color: rgba(243, 243, 243, 0.8);
				font-style: italic;
				font-size: 17px;
				font-weight: 300;
			}

			&:focus {
				outline: none;
			}
		}

		&__amount-of-money {
			position: relative;

			input[type='number'] {
				padding-left: 30px;
			}

			&::before {
				content: '$';
				position: absolute;
				top: 12px;
				font-size: 18px;
				left: 15px;
			}
		}

		&__input[type='radio']:checked + &__label:not(.quiz-option__label-other) {
			background-color: $color-brand-primary;
			-webkit-animation: choice-label-blink 0.75s ease-in-out;
			animation: choice-label-blink 0.75s ease-in-out;
			-webkit-animation-fill-mode: forwards;
			animation-fill-mode: forwards;

			&::before {
				background: url('/images/icon-radio-checked.svg') no-repeat center
					center;
				background-size: contain;
			}
		}

		&__input[type='radio']:checked + &__label.quiz-option__label-other {
			background-color: $color-brand-primary;

			&::before {
				background: url('/images/icon-radio-checked.svg') no-repeat center
					center;
				background-size: contain;
			}
		}

		&-other {
			.quiz-option__input-other {
				display: none;
			}

			.quiz-option__input[type='radio']:checked + .quiz-option__label {
				font-size: 0;
				flex-direction: row-reverse;

				.quiz-option__input-other {
					display: block;
					border: none;
					background-color: transparent;
					outline: none;
					font-size: 17px;
					line-height: 1.3;
					letter-spacing: 0.24px;
					color: #f3f3f3;
					font-style: italic;
					font-weight: 500;
					text-align: left;
					flex-grow: 1;

					&::-webkit-input-placeholder {
						color: #f3f3f3;
					}

					&::-moz-placeholder {
						color: #f3f3f3;
					}

					&:-ms-input-placeholder {
						color: #f3f3f3;
					}

					&::-ms-input-placeholder {
						color: #f3f3f3;
					}

					&::placeholder {
						color: #f3f3f3;
					}
				}

				&::before {
					background: url('/images/icon-radio-not-checked.svg') no-repeat center
						center;
					background-size: contain;
				}
			}
		}

		&__input[type='radio']:checked + .quiz-option__label {
			&-audio-choice {
				&::after {
					content: '';
					display: block;
					width: 24px;
					height: 24px;
					background: url('/images/icon-audio-choice.svg') no-repeat center
						center;
					background-size: contain;
					margin-left: auto;
				}
			}
		}
	}

	&__footer {
		padding: 12px 0;
		border-top: 1px solid rgba(255, 255, 255, 0.2);
		margin-top: auto;
		display: flex;
		justify-content: space-between;
		gap: 15px;

		@media (min-width: 1080px) {
			margin-top: 50px;
			margin-bottom: auto;
		}
	}

	&__button-next,
	&__button-submit {
		color: $color-dark-900 !important;
		font-size: 17px;
		background: #ffffff !important;
		line-height: 1.5;
		letter-spacing: 0.5px;
		font-weight: 600;
		flex-grow: 1;
	}

	&__button-skip {
		font-size: 17px;
		line-height: 1.3;
		letter-spacing: 0.5px;
		font-weight: 600;
		flex-grow: 0;
	}

	&__post-field-description {
		margin: 55px 0 auto 6px;
		padding: 9px 15px 46px 20px;
		color: rgba(255, 255, 255, 0.5);
		font-size: 17px;
		line-height: 1.6;
		font-weight: normal;
		border-left: 1px solid #3c3c50;
		position: relative;

		&::after {
			display: block;
			content: '';
			position: absolute;
			top: -12px;
			left: -6px;
			width: 12px;
			height: 12px;
			background: url('/images/icon-form-description-circle.svg') no-repeat
				center center;
			background-size: contain;
		}
	}

	&-loading {
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 200;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(1, 7, 38, 0.75);

		&--dark {
			background: rgba(1, 7, 38, 1);
		}
	}
}

@-webkit-keyframes choice-label-blink {
	0% {
		background-color: $color-dark-900;
	}

	20% {
		background-color: $color-brand-primary;
	}

	40% {
		background-color: $color-dark-900;
	}

	60% {
		background-color: $color-brand-primary;
	}

	80% {
		background-color: $color-dark-900;
	}

	100% {
		background-color: $color-brand-primary;
	}
}
