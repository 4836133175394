.modal-audio-sample {
	.modal__body {
		max-height: 90vh;
		z-index: 2;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		background: linear-gradient(rgba(1, 7, 38, 0.75), rgba(1, 7, 38, 0.75)),
			url('/images/bg-modal-audio-sample.jpg');
		background-repeat: no-repeat, no-repeat;
		background-position: center, center;
		background-size: cover, cover;
		width: 100%;
		max-width: 90vw;
		border-radius: 12px;
		overflow: scroll;
		scrollbar-width: none;
		-ms-overflow-style: none;
		max-width: 90vw;
		border: 2px solid rgba(255, 255, 255, 0.8);

		&::-webkit-scrollbar {
			display: none;
		}

		@media (min-width: 768px) {
			max-width: 700px;
		}
	}

	&__duration {
		font-size: 16px;
		line-height: 1.53;
		font-weight: normal;
		letter-spacing: 0.24px;
		margin: 0 0 0 auto;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 26px 20px 14px 14px;
		align-self: stretch;
		order: 1;
	}

	&__title {
		margin: 0 auto;
		text-align: center;
		font-size: 24px;
		line-height: 1.45;
		letter-spacing: 0.56px;
		font-weight: 600;
		padding-left: 18px;
		padding-right: 18px;
		order: 2;

		@media (min-width: 768px) {
			font-size: 24px;
		}
	}

	&__content {
		padding: 20px 18px 14px 20px;
		border-top: 1px solid #ffffff;
		order: 5;
	}

	&__text {
		font-size: 16px;
		font-weight: normal;
		line-height: 1.5;
		margin: 0 auto 13px;
		letter-spacing: 1.05px;
		color: rgba(243, 243, 243, 0.9);

		@media (min-width: 768px) {
			margin-bottom: 20px;
			font-size: 18px;
		}
	}

	&__footer {
		margin-top: 25px;
		display: flex;
		gap: 20px;
		flex-direction: column-reverse;
		padding: 15px 15px 20px 20px;
		order: 3;

		@media (min-width: 768px) {
			flex-direction: column;
		}
	}

	&__footer-text {
		font-size: 16px;
		line-height: 1.5;
		letter-spacing: 0.75px;
		margin: 0 auto;

		@media (min-width: 768px) {
			font-size: 18px;
			margin-bottom: 15px;
		}
	}

	&__highlighted-text {
		font-weight: normal;
		color: #ffe923;
	}

	&__audio-wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 11px;
	}

	&__play-btn {
		width: 33px;
		height: 33px;
		background: url('/images/icon-play.svg') no-repeat center center;
		background-size: contain;
		border: none;
		font-size: 0;
		cursor: pointer;

		&--playing {
			background: url('/images/icon-pause.svg') no-repeat center center;
		}
	}

	&__waveform {
		flex-grow: 1;
	}

	.btn {
		display: block;
		margin: 0 auto 25px;
		padding: 13px 20px;
		border-radius: 12px;
		border: none;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		text-decoration: none;
		background: #ffffff;
		order: 4;

		&-cta {
			max-width: 95%;

			.btn-label {
				@include gradient-text($gradient-text-dark);
				font-size: 16px;
				line-height: 1.2;
				font-weight: 600;

				@media (min-width: 768px) {
					font-size: 18px;
					letter-spacing: 0.75px;
				}
			}

			@media (max-width: 480px) {
				padding: 13px 12px;
			}
		}

		&-icon {
			gap: 6px;

			&::before {
				content: '';
				display: block;
				background: url('/images/icon-stars.svg') no-repeat center center;
				width: 20px;
				height: 20px;
				background-size: cover;
			}
		}
	}
}
