.gradient-card-text-block {
	&__content {
		background: linear-gradient(120deg, #246fff 0%, #562dae 50%, #6f09ae 100%);
		border-radius: 12px;
		border: 1px solid #ffffff;
		padding: 31px 0 35px;
		max-width: 600px;
		margin-left: auto;
		margin-right: auto;
	}

	&__title {
		margin: 0 auto 12px;
		border-bottom: 1px solid rgba(255, 255, 255, 0.2);
		padding-left: 18px;
		padding-right: 20px;
		padding-bottom: 24px;
		text-align: center;
		font-weight: 400;
		font-size: 24px;
		line-height: 1.5;
		letter-spacing: 1px;
		text-transform: capitalize;

		@media (min-width: 768px) {
			font-size: 28px;
		}
	}

	&__text {
		padding-left: 18px;
		padding-right: 18px;
		font-size: 17px;
		line-height: 1.5;
		letter-spacing: 1px;
		font-weight: 400;
		color: rgba(255, 255, 255, 0.97);
		margin: 0 auto 22px;

		@media (min-width: 768px) {
			font-size: 18px;
		}
	}
}
