.guarantee {
	margin-bottom: 130px;

	.wrapper {
		padding-top: 75px;
		position: relative;

		max-width: 650px;
	}

	&__content {
		position: relative;
		background-color: #ffffff;
		border-radius: 10px;
		padding: 38px 7px 45px 12px;

		&::after {
			content: '';
			display: block;
			width: 140px;
			height: 140px;
			position: absolute;
			background: url('/images/label-moneyback-guarantee.png') no-repeat center
				center;
			background-size: contain;
			right: -15px;
			bottom: -60px;
		}
	}

	&__title {
		position: absolute;
		top: -80px;
		left: 50%;
		transform: translateX(-50%);
		background: url('/images/label-risk-free-guarantee.svg') no-repeat center
			center;
		background-size: contain;
		width: 175px;
		padding: 15px 100px 30px;
		height: 55px;
		margin: 0 auto;
		overflow: hidden;
		font-weight: 600;
		line-height: 25px;
		letter-spacing: 0.15px;
		text-align: center;
		line-height: 1.1;
		font-size: 23px;
		color: transparent;
	}

	&__photo {
		width: 100%;
		display: block;
		max-width: 500px;
		height: auto;
		border-radius: 10px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 15px;
	}

	&__text {
		font-size: 17px;
		line-height: 1.5;
		letter-spacing: 0.75px;
		padding-left: 10px;
		padding-right: 10px;
		font-weight: 900;
		font-style: italic;
		color: #0c0516;
		text-align: center;
		max-width: 500px;
		margin: 0 auto 20px;

		@media (min-width: 768px) {
			font-size: 19px;
			letter-spacing: 0.75px;
		}
	}

	&__founder-signature {
		transform: rotate(-5deg);
		position: absolute;
		left: 8px;
		bottom: 14px;
	}
}
