.questions-item {
	border-radius: 12px;
	background-color: $color-dark-800;
	padding: 15px 5px 20px 19px;
	margin: 0 auto 25px;
	overflow: hidden;
	cursor: pointer;

	&__title {
		font-size: 18px;
		letter-spacing: 1.75px;
		line-height: 1.42;
		color: #f3f3f3;
		font-weight: 400;
		margin: 0 auto;
		display: flex;
		align-items: center;

		@media (min-width: 768px) {
			font-size: 20px;
		}

		&::before {
			content: '';
			display: block;
			background: url('/images/icon-question.svg') no-repeat center center;
			width: 24px;
			height: 24px;
			margin-right: 8px;
			background-size: contain;
			flex-shrink: 0;
		}

		&::after {
			transform: rotate(180deg);
			content: '';
			display: block;
			background: url('/images/icon-accordion-arrow.svg') no-repeat center
				center;
			width: 24px;
			height: 24px;
			margin-left: 10px;
			margin-top: -2px;
			background-size: contain;
			flex-shrink: 0;
			transition: transform 0.25s ease-in-out;

			@media (min-width: 768px) {
				margin-left: auto;
				margin-right: 15px;
			}
		}
	}

	&__answer {
		transition:
			max-height 0.25s ease,
			opacity 0.25s ease;
		max-height: 0;
		padding-left: 14px;
		padding-right: 12px;
		opacity: 0;
		overflow: hidden;
	}

	&__text {
		margin: 20px auto 0;
		font-weight: normal;
		line-height: 1.5;
		font-size: 17px;
		letter-spacing: 1.25px;

		&:first-child {
			margin-top: 40px;
		}

		@media (min-width: 768px) {
			font-size: 18px;
		}
	}

	&--active {
		.questions-item__title {
			&::after {
				transform: rotate(360deg);
			}
		}

		.questions-item__answer {
			max-height: 1500px;
			opacity: 1;
		}
	}
}
