@import 'mixins.scss';
@import 'variables.scss';
@import 'fonts.scss';
@import 'animations.scss';
@import 'page-loading.scss';
@import '../components/buttons.scss';

html {
	background: #010726;
	scroll-behavior: smooth;
}

body {
	padding: 0;
	margin: 0;
	color: rgba(255, 255, 255, 0.97);
	font-size: 15px;
	line-height: 1.2;
	letter-spacing: 0.25px;
	font-family: 'SF Pro Display', sans-serif;
	background: #010726;
}

.wrapper {
	max-width: 500px;
	margin: 0 auto;
	padding: 0 15px;

	@media (min-width: 768px) {
		max-width: 950px;
	}
}

.highlighted-text {
	@include gradient-text($gradient-text);
	display: inline;
	font-weight: inherit;
	letter-spacing: inherit;
	font-size: inherit;
	line-height: inherit;

	&_dark {
		@include gradient-text($gradient-text-dark);
	}
}

.hidden {
	display: none !important;
}

.visibility-hidden {
	visibility: hidden;
}

.no-scroll {
	overflow: hidden;
}

.fixed-footer {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 100;

	&__content {
		padding-top: 15px;
		padding-bottom: 30px;
		border-top: 1px solid rgba(255, 255, 255, 0.2);
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

.page {
	&__content {
		max-width: 100vw;
		overflow-x: hidden;
	}
}

#cookies-notification {
	font-size: 15px;

	position: fixed;
	z-index: 9999 !important;
	right: 0;
	bottom: 0;
	left: 0;

	width: 100%;
	padding: 10px;

	text-align: center;
	letter-spacing: 1.5px;

	color: #fff;
	background-color: rgba(1, 7, 38, 0.6);

	@media (min-width: 768px) {
		font-size: 18px;
	}
}

#cookies-notification .container {
	display: flex;
	display: flex;
	align-items: center;
	align-items: center;
	flex-direction: column;
	flex-direction: row;
	justify-content: space-between;
	gap: 10px;
	box-sizing: border-box;

	width: 95vw;
	max-width: 950px;
	margin: 0 auto;
	padding-right: 16px;
	padding-left: 16px;

	-moz-text-align-last: left;
	text-align-last: left;
}

#accept-cookies-btn {
	margin-bottom: 0;
	padding: 10px 25px;
}

#cookies-notification p {
	margin: 0;
	padding: 0;

	text-align: left;
}

.btn-framed {
	font-family: 'SF Pro Display';
	font-size: 16px;
	font-weight: 400;
	line-height: 1.5;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-bottom: 25px;
	padding: 8px 16px;

	cursor: pointer;
	letter-spacing: 1px;

	color: #f3f3f3;
	border: 1px solid rgba(255, 255, 255, 0.2);
	border-radius: 20px;
	outline: none;
	background: rgba(1, 7, 38, 0.2);

	gap: 6px;
	-webkit-backdrop-filter: blur(8px);
	backdrop-filter: blur(8px);
}

picture img {
	width: 100%;
	height: auto;
}

button {
	cursor: pointer;
}
