.video-section {
	margin-bottom: 10px;
	max-width: 375px;
	margin-left: auto;
	margin-right: auto;

	.wrapper {
		position: relative;
	}

	.video-player {
		border-radius: 5px;
		border: 3px solid #ffffff;
		width: 100%;
	}

	.btn-play-video {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		transform: none;

		&--autoplay {
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			transform: none;
			background: rgba(1, 7, 38, 0.65);
		}

		&--video-story {
			img {
				position: relative;
				top: 100px;
			}
		}
	}
}

.video-guide {
	&__title {
		font-size: 28px;
		font-weight: 400;
		line-height: 1.3;
		color: #f3f3f3;
		margin: 0 auto 35px;
		text-align: center;
		letter-spacing: 0.76px;
		max-width: 600px;

		@media (min-width: 768px) {
			font-size: 28px;
		}
	}
}
