.modal {
	display: flex;
	position: fixed;
	align-items: center;
	justify-content: center;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 100;
	transform: translate3d(0, 0, 0);

	&__body {
		max-height: 90vh;
		z-index: 2;
		background-repeat: no-repeat;
		background-position: center top;
		background-size: cover;
		width: 350px;
		border-radius: 12px;
		overflow: scroll;
		scrollbar-width: none;
		-ms-overflow-style: none;

		&::-webkit-scrollbar {
			display: none;
		}
	}

	&__overlay {
		position: absolute;
		cursor: pointer;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: rgba(255, 255, 255, 0.05);
		-webkit-backdrop-filter: blur(4px);
		backdrop-filter: blur(4px);
		z-index: 1;
	}

	&__body {
		position: absolute;
		display: flex;
		flex-direction: column;
		align-items: stretch;
		justify-content: center;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&__back-btn {
		padding: 11px 14px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		border: none;
		background: url('/images/icon-arrow-left.svg'), rgba(255, 255, 255, 0.2);
		background-repeat: no-repeat;
		background-position: 45% center;
		font-size: 0;
		cursor: pointer;
		width: 32px;
		height: 32px;
		background-size: 9px 15px;
		transition: all 0.25s ease-in-out;

		&:hover {
			background-color: rgba(255, 255, 255, 0.5);
		}
	}

	&-info {
		.modal__body {
			background: #1e1e32;
			border-radius: 18px;
			border: 1px solid #ffffff;
			display: flex;
			flex-direction: column;
			align-items: center;
			max-width: 90vw;
		}

		&__title {
			padding: 17px 0;
			text-align: center;
			margin: 0 auto;
			font-weight: 500;
			font-size: 18px;
			line-height: 1.5;
			letter-spacing: 0.7px;
			color: #f3f3f3;
			border-bottom: 1px solid rgba(255, 255, 255, 0.4);
			width: 100%;
		}

		&__content {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 11px 0 22px;
			width: 100%;
			text-align: center;
			font-size: 16px;
			line-height: 1.5;
			letter-spacing: 0.7px;
			font-weight: 400;
			color: #f3f3f3;
		}

		&__icon {
			display: block;
			width: 50px;
			height: 50px;
			margin-bottom: 10px;
		}

		&__message {
			padding: 0 10px;
			margin: 0;
			max-width: 300px;
		}

		&__footer {
			border-top: 1px solid rgba(255, 255, 255, 0.4);
			width: 100%;
			padding: 10px 0;

			.btn {
				width: 93%;
				margin: 0 auto;

				&-label {
					font-size: 16px;
					font-weight: 600;
					line-height: 1.5;
					color: #1e1e32;
					letter-spacing: 0.7px;
				}
			}
		}
	}
}
