@font-face {
	font-family: 'SF Pro Display';
	src: url('SFProDisplay-Medium.eot');
	src:
		local('SF Pro Display Medium'),
		local('SFProDisplay-Medium'),
		url('/fonts/SFProDisplay-Medium.eot?#iefix') format('embedded-opentype'),
		url('/fonts/SFProDisplay-Medium.woff2') format('woff2'),
		url('/fonts/SFProDisplay-Medium.woff') format('woff'),
		url('/fonts/SFProDisplay-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'SF Pro Display';
	src: url('SFProDisplay-Heavy.eot');
	src:
		local('SF Pro Display Heavy'),
		local('SFProDisplay-Heavy'),
		url('/fonts/SFProDisplay-Heavy.eot?#iefix') format('embedded-opentype'),
		url('/fonts/SFProDisplay-Heavy.woff2') format('woff2'),
		url('/fonts/SFProDisplay-Heavy.woff') format('woff'),
		url('/fonts/SFProDisplay-Heavy.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'SF Pro Display';
	src: url('SFProDisplay-UltralightItalic.eot');
	src:
		local('SF Pro Display UltralightItalic'),
		local('SFProDisplay-UltralightItalic'),
		url('/fonts/SFProDisplay-UltralightItalic.eot?#iefix')
			format('embedded-opentype'),
		url('/fonts/SFProDisplay-UltralightItalic.woff2') format('woff2'),
		url('/fonts/SFProDisplay-UltralightItalic.woff') format('woff'),
		url('/fonts/SFProDisplay-UltralightItalic.ttf') format('truetype');
	font-weight: 200;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'SF Pro Display';
	src: url('SFProDisplay-RegularItalic.eot');
	src:
		local('SF Pro Display Regular Italic'),
		local('SFProDisplay-RegularItalic'),
		url('/fonts/SFProDisplay-RegularItalic.eot?#iefix')
			format('embedded-opentype'),
		url('/fonts/SFProDisplay-RegularItalic.woff2') format('woff2'),
		url('/fonts/SFProDisplay-RegularItalic.woff') format('woff'),
		url('/fonts/SFProDisplay-RegularItalic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'SF Pro Display';
	src: url('SFProDisplay-Thin.eot');
	src:
		local('SF Pro Display Thin'),
		local('SFProDisplay-Thin'),
		url('/fonts/SFProDisplay-Thin.eot?#iefix') format('embedded-opentype'),
		url('/fonts/SFProDisplay-Thin.woff2') format('woff2'),
		url('/fonts/SFProDisplay-Thin.woff') format('woff'),
		url('/fonts/SFProDisplay-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'SF Pro Display';
	src: url('SFProDisplay-Light.eot');
	src:
		local('SF Pro Display Light'),
		local('SFProDisplay-Light'),
		url('/fonts/SFProDisplay-Light.eot?#iefix') format('embedded-opentype'),
		url('/fonts/SFProDisplay-Light.woff2') format('woff2'),
		url('/fonts/SFProDisplay-Light.woff') format('woff'),
		url('/fonts/SFProDisplay-Light.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'SF Pro Display';
	src: url('SFProDisplay-BlackItalic.eot');
	src:
		local('SF Pro Display Black Italic'),
		local('SFProDisplay-BlackItalic'),
		url('/fonts/SFProDisplay-BlackItalic.eot?#iefix')
			format('embedded-opentype'),
		url('/fonts/SFProDisplay-BlackItalic.woff2') format('woff2'),
		url('/fonts/SFProDisplay-BlackItalic.woff') format('woff'),
		url('/fonts/SFProDisplay-BlackItalic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'SF Pro Display';
	src: url('SFProDisplay-Bold.eot');
	src:
		local('SF Pro Display Bold'),
		local('SFProDisplay-Bold'),
		url('/fonts/SFProDisplay-Bold.eot?#iefix') format('embedded-opentype'),
		url('/fonts/SFProDisplay-Bold.woff2') format('woff2'),
		url('/fonts/SFProDisplay-Bold.woff') format('woff'),
		url('/fonts/SFProDisplay-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'SF Pro Display';
	src: url('SFProDisplay-Black.eot');
	src:
		local('SF Pro Display Black'),
		local('SFProDisplay-Black'),
		url('/fonts/SFProDisplay-Black.eot?#iefix') format('embedded-opentype'),
		url('/fonts/SFProDisplay-Black.woff2') format('woff2'),
		url('/fonts/SFProDisplay-Black.woff') format('woff'),
		url('/fonts/SFProDisplay-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'SF Pro Display';
	src: url('SFProDisplay-SemiboldItalic.eot');
	src:
		local('SF Pro Display SemiboldItalic'),
		local('SFProDisplay-SemiboldItalic'),
		url('/fonts/SFProDisplay-SemiboldItalic.eot?#iefix')
			format('embedded-opentype'),
		url('/fonts/SFProDisplay-SemiboldItalic.woff2') format('woff2'),
		url('/fonts/SFProDisplay-SemiboldItalic.woff') format('woff'),
		url('/fonts/SFProDisplay-SemiboldItalic.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'SF Pro Display';
	src: url('SFProDisplay-Ultralight.eot');
	src:
		local('SF Pro Display Ultralight'),
		local('SFProDisplay-Ultralight'),
		url('/fonts/SFProDisplay-Ultralight.eot?#iefix') format('embedded-opentype'),
		url('/fonts/SFProDisplay-Ultralight.woff2') format('woff2'),
		url('/fonts/SFProDisplay-Ultralight.woff') format('woff'),
		url('/fonts/SFProDisplay-Ultralight.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'SF Pro Display';
	src: url('SFProDisplay-LightItalic.eot');
	src:
		local('SF Pro Display LightItalic'),
		local('SFProDisplay-LightItalic'),
		url('/fonts/SFProDisplay-LightItalic.eot?#iefix')
			format('embedded-opentype'),
		url('/fonts/SFProDisplay-LightItalic.woff2') format('woff2'),
		url('/fonts/SFProDisplay-LightItalic.woff') format('woff'),
		url('/fonts/SFProDisplay-LightItalic.ttf') format('truetype');
	font-weight: 200;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'SF Pro Display';
	src: url('SFProDisplay-ThinItalic.eot');
	src:
		local('SF Pro Display ThinItalic'),
		local('SFProDisplay-ThinItalic'),
		url('/fonts/SFProDisplay-ThinItalic.eot?#iefix') format('embedded-opentype'),
		url('/fonts/SFProDisplay-ThinItalic.woff2') format('woff2'),
		url('/fonts/SFProDisplay-ThinItalic.woff') format('woff'),
		url('/fonts/SFProDisplay-ThinItalic.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'SF Pro Display';
	src: url('SFProDisplay-MediumItalic.eot');
	src:
		local('SF Pro Display MediumItalic'),
		local('SFProDisplay-MediumItalic'),
		url('/fonts/SFProDisplay-MediumItalic.eot?#iefix')
			format('embedded-opentype'),
		url('/fonts/SFProDisplay-MediumItalic.woff2') format('woff2'),
		url('/fonts/SFProDisplay-MediumItalic.woff') format('woff'),
		url('/fonts/SFProDisplay-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'SF Pro Display';
	src: url('SFProDisplay-Semibold.eot');
	src:
		local('SF Pro Display Semibold'),
		local('SFProDisplay-Semibold'),
		url('/fonts/SFProDisplay-Semibold.eot?#iefix') format('embedded-opentype'),
		url('/fonts/SFProDisplay-Semibold.woff2') format('woff2'),
		url('/fonts/SFProDisplay-Semibold.woff') format('woff'),
		url('/fonts/SFProDisplay-Semibold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'SF Pro Display';
	src: url('SFProDisplay-HeavyItalic.eot');
	src:
		local('SF Pro Display HeavyItalic'),
		local('SFProDisplay-HeavyItalic'),
		url('/fonts/SFProDisplay-HeavyItalic.eot?#iefix')
			format('embedded-opentype'),
		url('/fonts/SFProDisplay-HeavyItalic.woff2') format('woff2'),
		url('/fonts/SFProDisplay-HeavyItalic.woff') format('woff'),
		url('/fonts/SFProDisplay-HeavyItalic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'SF Pro Display';
	src: url('SFProDisplay-Regular.eot');
	src:
		local('SF Pro Display Regular'),
		local('SFProDisplay-Regular'),
		url('/fonts/SFProDisplay-Regular.eot?#iefix') format('embedded-opentype'),
		url('/fonts/SFProDisplay-Regular.woff2') format('woff2'),
		url('/fonts/SFProDisplay-Regular.woff') format('woff'),
		url('/fonts/SFProDisplay-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'SF Pro Display';
	src: url('SFProDisplay-BoldItalic.eot');
	src:
		local('SF Pro Display BoldItalic'),
		local('SFProDisplay-BoldItalic'),
		url('/fonts/SFProDisplay-BoldItalic.eot?#iefix') format('embedded-opentype'),
		url('/fonts/SFProDisplay-BoldItalic.woff2') format('woff2'),
		url('/fonts/SFProDisplay-BoldItalic.woff') format('woff'),
		url('/fonts/SFProDisplay-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}
