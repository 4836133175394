.people-stories-list-wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 0;
	margin-bottom: 18px;
}

.people-stories-list {
	@include reset-list-styles;

	border-radius: 12px;
	width: 100%;
	flex-grow: 1;

	&__control-btn {
		position: relative;
		border: none;
		cursor: pointer;
		top: -25px;

		button {
			background: url('/images/icon-arrow-left.svg') no-repeat center center;
			width: 20px;
			height: 20px;
			padding: 5px;
			font-size: 0;
			border: none;

			@media (min-width: 768px) {
				width: 30px;
				height: 30px;
				padding: 10px;
			}
		}

		&--prev {
			left: -5px;
		}

		&--next {
			right: -5px;

			button {
				transform: rotate(180deg);
			}
		}
	}

	&__pagination {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 5px;

		@media (min-width: 768px) {
			gap: 10px;
		}

		&-page {
			cursor: pointer;
			width: 10px;
			height: 10px;
			border-radius: 50%;
			background-color: #d9d9d9;

			@media (min-width: 768px) {
				width: 15px;
				height: 15px;
			}

			&--current {
				background-color: #94a6f8;
			}
		}
	}
}

.people-story-item {
	border-radius: 11px;
	overflow: hidden;

	&__header {
		background: $gradient-border;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 12px 12px 5px;

		@media (min-width: 768px) {
			padding: 24px 24px 10px;
		}
	}

	&__photo {
		overflow: hidden;
		border-radius: 50%;
		width: 70px;
		height: 70px;
		-o-object-fit: cover;
		object-fit: cover;

		@media (min-width: 768px) {
			width: 100px;
			height: 100px;
			margin-bottom: 15px;
		}
	}

	&__name {
		font-size: 17px;
		line-height: 1.75;
		letter-spacing: 0.75px;
		font-weight: 400;
		margin: 0 auto;

		@media (min-width: 768px) {
			font-size: 19px;
		}
	}

	&__blockquote {
		margin: 0 auto;
		padding: 15px 10px 6px;
		background-color: #d9d9d9;
		font-weight: 900;
		font-style: italic;
		font-size: 17px;
		line-height: 1.5;
		color: #0c0516;
		letter-spacing: 0.75px;
		text-align: center;

		@media (min-width: 768px) {
			padding: 30px 60px 26px;
			font-size: 18px;
		}
	}

	&__quote {
		margin: 0 auto;
	}
}
