@mixin gradient-text($gradient) {
	background: $gradient;
	-webkit-background-clip: text;
	background-clip: text;
	color: transparent;
}

@mixin gradient-border($width, $gradient) {
	padding: $width;
	background-image: $gradient;
	background-origin: border-box;
	background-clip: border-box;
	overflow: hidden;
}

@mixin reset-list-styles {
	list-style: none;
	margin: 0;
	padding: 0;
}

@mixin gradient-card {
	background: linear-gradient(120deg, #246fff 0%, #562dae 50%, #6f09ae 100%);
	border-radius: 12px;
	border: 1px solid #ffffff;
	padding: 31px 0 35px;
}
