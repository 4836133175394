.btn {
	display: block;
	text-decoration: none;
	margin: 0 auto;
	padding: 13px 20px;
	border-radius: 12px;
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	&-cta {
		.btn-label {
			@include gradient-text($gradient-text-dark);
			font-size: 16px;
			line-height: 1.2;
			font-weight: 600;

			@media (min-width: 768px) {
				font-size: 18px;
				letter-spacing: 0.75px;
			}

			@media (max-width: 380px) {
				letter-spacing: -1px !important;
			}
		}

		@media (max-width: 480px) {
			padding: 13px 15px;
		}

		@media (max-width: 380px) {
			letter-spacing: -1px;
		}
	}

	&-icon {
		gap: 6px;

		&::before {
			content: '';
			display: block;
			background: url('/images/icon-stars.svg') no-repeat center center;
			width: 20px;
			height: 20px;
			background-size: cover;
		}
	}

	&-download-the-app {
		width: 100%;
		max-width: 360px;
		display: flex;

		&::before {
			background: url('/images/download-colored.svg') no-repeat center center;
			width: 28px;
			height: 28px;
		}
	}

	&--secondary {
		font-weight: 500;
		font-size: 15px;
		line-height: 1.33;
		color: #ffffff;
		border-radius: 12px;
		background-color: rgba(255, 255, 255, 0.1);
		border: 1px solid rgba(255, 255, 255, 0.2);
		padding: 12px 19px;
	}
}

.btn-play-video {
	background: transparent;
	border: none;
	cursor: pointer;
}
