.people-quotes-list-wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 10px;
}

.people-quotes-list {
	@include reset-list-styles;

	&__control-btn {
		position: relative;
		border: none;
		cursor: pointer;
		top: -25px;

		button {
			background: url('/images/icon-arrow-left.svg') no-repeat center center;
			width: 20px;
			height: 20px;
			padding: 5px;
			font-size: 0;
			border: none;
		}

		&--prev {
			left: -5px;
		}

		&--next {
			right: -5px;

			button {
				transform: rotate(180deg);
			}
		}
	}
}

.people-quote-item {
	background: #4267b2;
	border-radius: 11px;

	&__blockquote {
		margin: 0 auto;
		padding: 90px 20px 38px 38px;
		position: relative;
		font-weight: 700;
		font-size: 25px;
		line-height: 1.5;
		color: #ffffff;
		letter-spacing: 0.75px;
		border-bottom: 1px solid rgba(192, 192, 192, 0.8);

		&::before {
			content: '';
			position: absolute;
			top: 22px;
			left: 12px;
			display: block;
			width: 41px;
			height: 40px;
			background: url('/images/icon-quote.svg') no-repeat center center;
			background-size: contain;
		}
	}

	&__quote {
		margin: 0 auto;
	}

	&__author {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 28px 17px 28px 38px;
		gap: 14px;
	}

	&__author-image {
		width: 65px;
		height: 65px;
		border-radius: 50%;
		overflow: hidden;
		flex-shrink: 0;
		align-self: flex-start;

		@media (max-width: 420px) {
			width: 70px;
			height: 70px;
		}

		@media (min-width: 768px) {
			width: 80px;
			height: 80px;
		}
	}

	&__author-info {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-between;
		gap: 11px;
		text-align: left;
	}

	&__author-name {
		font-size: 18px;
		line-height: 1.5;
		font-weight: 700;
		margin: 0;

		@media (min-width: 768px) {
			font-size: 18px;
			letter-spacing: 1.25px;
		}
	}

	&__author-occupation {
		margin: 0;
		font-size: 17px;
		line-height: 1.5;
		font-weight: 400;

		@media (min-width: 768px) {
			font-size: 18px;
			letter-spacing: 1.25px;
		}
	}
}
