@import 'common/main.scss';

.page {
	&__background {
		background: #010726;
	}

	&__content {
		background: #010726;
		padding-bottom: 150px;
	}
}

.lead-screen {
	background:
		linear-gradient(
			135deg,
			rgba(2, 12, 53, 1) 2%,
			rgba(2, 12, 53, 0.9) 25%,
			rgba(1, 7, 34, 0.7847514005602241) 100%
		),
		url('/images/bg-lead-screen.jpg') no-repeat center center;
	background-size: 100vw auto;
	background-position: top center;

	@media (min-width: 768px) {
		background-image: url('/images/bg-lead-screen-big.jpg');
		background-repeat: no-repeat;
		background-size: cover;
	}

	&__title {
		line-height: 1.3;
		text-align: center;
		font-size: 32px;
		font-weight: 500;
		margin: 0 auto 34px;
		letter-spacing: 0.7px;

		@media (min-width: 768px) {
			margin-bottom: 80px;
		}
	}

	.wrapper {
		padding-top: 32px;
		padding-bottom: 20px;

		@media (min-width: 768px) {
			padding-top: 125px;
		}
	}
}

.lead-screen .btn-cta {
	width: 94%;
	margin: 0 auto 3%;
	max-width: 404px;

	@media (min-width: 768px) {
		margin-top: 55px;
	}
}

.section__title {
	font-size: 28px;
	line-height: 1.3;
	letter-spacing: 0.46px;
	font-weight: 400;
	text-align: center;

	@media (min-width: 768px) {
		font-size: 30px;
		letter-spacing: 1.5;
		letter-spacing: 1.2px;
		max-width: 700px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 35px;
	}
}

.personal-story {
	.wrapper {
		padding-top: 32px;
	}

	&__title {
		margin-bottom: 35px;
	}
}

.personal-story-card {
	border-radius: 10px;
	@include gradient-border(3px, $gradient-border);
	width: 100%;
	margin-bottom: 40px;

	&__wrapper {
		border-radius: 10px;
		padding: 54px 12px;
		background: #d9d9d9;
	}

	&__photo {
		width: 100%;
		max-width: 500px;
		margin-left: auto;
		margin-right: auto;
		display: block;
		height: auto;
		border-radius: 10px;
		margin-bottom: 42px;
	}

	&__text {
		font-size: 18px;
		line-height: 1.5;
		letter-spacing: 0.74px;
		color: #0c0516;
		margin: 0 10px 23px;
	}
}

.video-story {
	margin-bottom: 10px;
	max-width: 375px;
	margin-left: auto;
	margin-right: auto;
}

.transformation-story {
	.wrapper {
		margin-left: auto;
		margin-right: auto;

		@media (max-width: 768px) {
			max-width: 500px;
		}
	}

	&__title {
		font-size: 18px;
		font-weight: 600;
		line-height: 1.35;
		color: #ffffff;
		margin: 0 auto 10px;
		padding: 0;
		text-align: center;
		max-width: 420px;
		letter-spacing: 0.75px;
		box-sizing: border-box;
		background: none;
		position: relative;

		&-background {
			display: block;
			width: 100%;
			height: auto;
			max-width: 500px;
			margin: 0 auto;
		}

		&-text {
			display: block;
			position: absolute;
			margin: 0;
			top: 19%;
			bottom: 40%;
			right: 9%;
			left: 8%;

			br {
				@media (max-width: 400px) {
					display: none;
				}
			}

			@media (max-width: 350px) {
				top: 25%;
				right: 8%;
				left: 5%;
				font-size: 15px;
				line-height: 1.35;
			}

			@media (max-width: 1280px) {
			}
		}

		@media (max-width: 450px) {
			padding: 0;
			font-size: 16px;
			line-height: 1.5;
			background-size: 360px auto;
			overflow: visible;
		}
	}

	&__content {
		max-width: 600px;
		border-bottom: 3px solid #c9bcb0;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		gap: 7px;

		@media (min-width: 768px) {
			flex-direction: row;
			padding-left: 40px;
			padding-right: 40px;
		}
	}

	&__texts {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: stretch;
	}

	&__text {
		font-size: 17px;
		line-height: 1.5;
		color: #ffffff;
		margin: 0 auto 23px 0;
		text-align: left;
		font-weight: 400;
		letter-spacing: 0.5px;

		@media (max-width: 768px) {
			margin-bottom: 5px;
			font-size: 18px;
			font-weight: 400;
			line-height: 1.5;
			letter-spacing: 1.25px;
		}
	}

	&__photo {
		float: right;
		width: 205px;
		height: 365px;
	}
}

.training-story {
	padding-top: 30px;
	padding-bottom: 30px;

	&__title-image {
		width: 100%;
		display: block;
		height: auto;
		max-width: 500px;
		margin: 0 auto;
	}

	&__title {
		font-size: 20px;
		font-weight: 700;
		line-height: 1.35;
		color: #ffffff;
		margin: 0 auto 38px;
		text-align: center;
		letter-spacing: 0.15px;
		width: 400px;
		max-width: 100vw;
		box-sizing: border-box;
		position: relative;

		@media (min-width: 768px) {
			font-size: 26px;
			width: 580px;
		}

		&-background {
			display: block;
			width: 100%;
			height: auto;
			max-width: 500px;
			margin: 0 auto;
		}

		&-text {
			display: block;
			position: absolute;
			margin: 0;
			top: 28%;
			left: 8%;
			bottom: 30%;
			right: 8%;
		}

		@media (max-width: 440px) {
			padding: 0;
			font-size: 18px;
			line-height: 1.35;
		}

		@media (max-width: 370px) {
			font-size: 16px;
			line-height: 1.35;
		}
	}

	&__text {
		font-size: 18px;
		font-weight: 400;
		line-height: 1.5;
		margin: 0 auto 17px;
		padding: 0 15px 0 15px;
		letter-spacing: 1.25px;
		max-width: 500px;

		@media (min-width: 768px) {
			margin-bottom: 25px;
		}
	}

	&__quote {
		font-weight: 700;
		line-height: 1.5;
		font-size: 18px;
		margin: 0 auto 60px;
		position: relative;
		text-align: center;
		transform: rotate(0deg);
		background-size: 100% auto;
		overflow-x: visible;
		letter-spacing: 0px;
		background-size: 420px auto;
		width: 600px;
		max-width: 100vw;
		box-sizing: border-box;
		margin-bottom: 40px;

		@media (min-width: 768px) {
			width: 650px;
			font-size: 20px;
			letter-spacing: 1.25px;
			line-height: 1.5;
			margin-bottom: 20px;
			margin-top: 30px;
		}

		&-background {
			display: block;
			width: 100%;
			height: auto;
			max-width: 580px;
			margin: 0 auto;

			@media (max-width: 500px) {
				width: 120%;
				max-width: 700px;
				margin-left: -10%;
			}
		}

		&-text {
			display: block;
			position: absolute;
			margin: 0;
			top: 34%;
			left: 15%;
			bottom: 30%;
			right: 18%;
			z-index: 10;
			transform: rotate(-3deg);

			@media (max-width: 768px) {
				top: 38%;
				left: 20%;
				right: 20%;
				font-size: 19px;
			}

			@media (max-width: 580px) {
				font-size: 18px;
				top: 35%;
				left: 20%;
				right: 20%;
			}

			@media (max-width: 500px) {
				left: 15%;
				right: 15%;
			}

			@media (max-width: 450px) {
				font-size: 17px;
				left: 5%;
				right: 5%;
			}

			@media (max-width: 355px) {
				top: 30%;
			}
		}

		@media (max-width: 440px) {
		}

		@media (max-width: 360px) {
		}
	}
}

.the-key {
	margin-bottom: 125px;

	.wrapper {
		max-width: 500px;

		@media (min-width: 768px) {
			max-width: 950px;
		}
	}

	&__title {
		font-size: 29px;
		font-weight: 500;
		line-height: 1.35;
		color: #ffffff;
		letter-spacing: 2px;
		text-align: center;
		margin: 0 auto 25px;

		@media (min-width: 768px) {
			font-size: 35px;
			margin-bottom: 40px;
		}

		.highlighted-text {
			display: block;
			margin-top: 15px;
		}
	}

	&__photo {
		width: 98%;
		max-width: 600px;
		height: auto;
		margin: 0 auto 35px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: auto;
		border-radius: 7px;

		@media (min-width: 768px) {
		}
	}

	&__content {
		max-width: 800px;
		margin-left: auto;
		margin-right: auto;

		@media (min-width: 768px) {
			display: flex;
			align-items: center;
			gap: 36px;
		}
	}

	&__text {
		font-size: 18px;
		line-height: 1.5;
		margin: 0 auto 20px;
		font-weight: 400;
		padding: 0 8px;
		letter-spacing: 1.25px;
		padding: 0 15px 0 15px;

		@media (max-width: 768px) {
			padding: 0;
		}

		@media (min-width: 768px) {
			font-size: 18px;
		}
	}

	.btn-cta {
		width: 100%;
		max-width: 420px;
		margin-top: 32px;
	}
}

.the-realization {
	padding: 32px 13px 13px;
	background: #d9d9d9;
	border-radius: 6px;
	margin: 0 auto 40px;
	color: #0c0516;
	max-width: 800px;

	.wrapper {
		@media (min-width: 768px) {
			display: flex;
			align-items: center;
			gap: 34px;
		}

		@media (max-width: 768px) {
			max-width: 500px;
		}
	}

	&__title {
		margin: 0 auto 55px;
		font-size: 24px;
		line-height: 1;
		font-weight: 600;
		letter-spacing: 0.5px;
		text-align: center;
		width: 100%;

		@media (min-width: 768px) {
			font-size: 28px;
			max-width: 90%;
			margin-bottom: 25px;
		}
	}

	&__text {
		margin: 0 0 25px;
		font-size: 19px;
		line-height: 1.5;
		letter-spacing: 0.74px;

		@media (min-width: 768px) {
			font-size: 18px;
		}
	}

	&__photo {
		width: auto;
		height: auto;
		border-radius: 4px;
		margin: 30px auto 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		img {
			width: 100%;
			height: auto;

			@media (min-width: 768px) {
				max-width: 100%;
			}
		}

		@media (max-width: 768px) {
			max-width: 100%;
		}

		@media (min-width: 768px) {
			margin-top: 0;
		}
	}
}

.the-proof {
	font-size: 18px;
	line-height: 1.5;
	color: #f9f9f9;
	padding-left: 5px;
	padding-bottom: 195px;
	background: url('/images/bg-the-proof.png') no-repeat center center;
	background-position: center bottom;
	background-size: contain;
	max-width: 800px;
	margin-left: auto;
	letter-spacing: 1.25px;
	margin-right: auto;
	font-weight: 400;

	@media (min-width: 768px) {
		margin-top: 35px;
		margin-bottom: 55px;
		padding-bottom: 50px;
		background: none;
	}

	&__text {
		margin: 0 auto 20px;
		letter-spacing: 1.25px;

		@media (min-width: 768px) {
			font-size: 18px;
			max-width: 600px;
			margin-left: auto;
			margin-right: auto;
		}
	}
}

.personalized-session-description {
	margin-bottom: 95px;
}

.audio-samples {
	&__title {
		font-size: 28px;
		font-weight: 400;
		line-height: 1.3;
		color: #f3f3f3;
		margin: 0 auto 35px;
		text-align: center;
		letter-spacing: 0.76px;
		max-width: 600px;

		@media (min-width: 768px) {
			font-size: 28px;
		}
	}
}

.audio-samples-list {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	@include reset-list-styles;
	margin: 25px auto 55px;
	-moz-column-gap: 16px;
	column-gap: 16px;
	align-items: stretch;
	row-gap: 20px;
	max-width: 450px;

	&__item {
		width: 45%;
		max-width: 180px;
		border-radius: 12px;
		border: 1px solid rgba(224, 229, 255, 0.7);
		padding: 18px 16px 18px 15px;
		display: flex;
		color: #fff;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		text-align: center;
		cursor: pointer;
		background-color: #050921;

		@media (min-width: 451px) and (max-width: 767px) {
			width: 38%;
			max-width: 150px;
		}

		@media (min-width: 373px) and (max-width: 450px) {
			width: 47%;
			max-width: 130px;
		}

		@media (max-width: 372px) {
			width: 35%;
			max-width: 130px;
		}

		&::after {
			content: '';
			display: block;
			width: 40px;
			height: 40px;
			background: url('/images/icon-play-circle.png') no-repeat center center;
			background-size: contain;
			z-index: 3;

			@media (min-width: 768px) {
				width: 45px;
				height: 45px;
			}
		}

		&:nth-child(10n + 1),
		&:nth-child(10n + 2),
		&:nth-child(10n + 3),
		&:nth-child(10n + 4) {
			position: relative;
			background-size: cover;
			background-position: center center;
		}

		&:nth-child(4n + 1) {
			background: url('/images/audio-samples/bg-primary.jpg') no-repeat center
				center;
			background-size: cover;
		}

		&:nth-child(4n + 2) {
			background: url('/images/audio-samples/bg-harmony.jpg') no-repeat center
				center;
			background-size: cover;
		}

		&:nth-child(4n + 3) {
			background: url('/images/audio-samples/bg-mindset-activation.jpg')
				no-repeat center center;
			background-size: cover;
		}

		&:nth-child(4n + 4) {
			background: url('/images/audio-samples/bg-gratitude-experience.jpg')
				no-repeat center center;
			background-size: cover;
		}

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(1, 7, 38, 0.8);
			z-index: 1;
			border-radius: inherit;
		}

		* {
			position: relative;
			z-index: 2;
		}
	}

	&__title {
		font-weight: 400;
		font-size: 17px;
		line-height: 1.25;
		margin: 0 auto 15px;
		letter-spacing: 0.75px;
		color: #fff;

		@media (min-width: 768px) {
			font-size: 18px;
		}
	}
}

.bonuses {
	margin-bottom: 135px;

	@media (max-width: 768px) {
		margin-bottom: 85px;
	}

	&__title {
		font-weight: 500;
		line-height: 1.5;
		font-size: 24px;
		letter-spacing: 0.75px;
		text-align: center;
		margin: 0 auto 20px;
		max-width: 700px;

		@media (min-width: 768px) {
			font-size: 28px;
			margin-bottom: 35px;
		}

		.highlighted-text {
			font-weight: 700;
		}
	}
}

.bonuses-list {
	@include reset-list-styles;
	padding-top: 45px;
	padding-left: 5px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	gap: 110px;

	@media (min-width: 768px) {
		flex-direction: row;
		flex-wrap: wrap;

		gap: 30px;
		row-gap: 50px;
	}

	&__item {
		position: relative;
		line-height: 2;

		@media (min-width: 768px) {
			&:first-child {
				margin-bottom: 40px;
			}

			&:nth-child(1n + 2) {
				max-width: 450px;
				width: 47%;
			}
		}

		&:first-child {
			.bonuses-list__item__content {
				padding-bottom: 0;
			}
		}

		&:last-child {
			.bonuses-list__item__content {
				padding-bottom: 30px;
			}
		}

		&::before {
			position: absolute;
			width: 108px;
			height: 108px;
			top: -54px;
			left: 50%;
			margin: 0 auto;
			margin-left: -54px;
			display: block;
			content: '';
			background: url('/images/icon-bonus-gift.png') no-repeat center center;
			background-size: contain;
		}

		&__content {
			@include gradient-card;
			padding-top: 40px;
			padding-bottom: 15px;
			margin-left: auto;
			margin-right: auto;
		}

		&:not(:first-child) {
			.bonuses-list__subtitle {
				padding-bottom: 15px;
				margin-bottom: 15px;
			}

			.bonuses-list__text {
				margin-bottom: 20px;
				font-weight: 400;
			}
		}
	}

	&__title {
		font-size: 23px;
		line-height: 1.45;
		letter-spacing: 0.75px;
		margin: 0 auto 10px;
		text-align: center;

		@media (min-width: 768px) {
			font-size: 25px;
		}
	}

	&__subtitle {
		text-align: center;
		margin: 0 auto 25px;
		padding: 5px 21px 10px 22px;
		font-size: 18px;
		line-height: 1.29;
		letter-spacing: 1.25px;
		font-weight: 400;
		border-bottom: 1px solid #ffffff;

		@media (min-width: 768px) {
			font-size: 20px !important;
		}
	}

	&__text {
		padding: 0 12px 0 13px;
		font-size: 17px;
		letter-spacing: 1px;
		line-height: 1.5;
		font-weight: 500;
		color: #fff;
		margin: 0 auto 20px;

		@media (min-width: 768px) {
			font-size: 18px;
			padding-right: 60px;
			padding-left: 60px;
		}

		&:last-child {
			margin-bottom: 10px;

			@media (min-width: 768px) {
				margin-bottom: 30px;
			}
		}
	}
}

.questions {
	margin-bottom: 105px;

	&__title {
		font-size: 25px;
		line-height: 1.5;
		letter-spacing: 0.75px;
		font-weight: 400;
		text-align: center;
		margin: 0 auto 28px;

		@media (min-width: 768px) {
			font-size: 30px;
		}
	}
}

.people-quotes {
	margin: 0 auto 70px;

	.wrapper {
		@media (min-width: 768px) {
			max-width: 850px;
		}
	}

	&__title {
		text-align: center;
		font-size: 24px;
		line-height: 1.2;
		font-weight: 500;
		letter-spacing: 0;
		margin: 0 auto 22px;

		@media (min-width: 768px) {
			font-size: 28px;
			margin-bottom: 35px;
		}
	}
}

.people-stories {
	margin: 0 auto 80px;

	&__title {
		margin: 0 auto 30px;
		font-weight: 500;
		font-size: 25px;
		line-height: 1.5;
		letter-spacing: 0;
		opacity: 0.9;
		color: #ffffff;
		text-align: center;

		@media (min-width: 768px) {
			font-size: 30px;
		}
	}

	.wrapper {
		@media (min-width: 768px) {
			max-width: 750px;
		}
	}
}

.footer-cta {
	padding-bottom: 150px;
}

.fixed-footer {
	background-color: rgba(1, 7, 38, 0.8);

	&__content {
		max-width: 500px;
		margin-left: auto;
		margin-right: auto;
	}

	.btn-cta {
		flex-grow: 0;
		max-width: 420px;
		background-color: #ffffff;

		@media (max-width: 380px) {
			letter-spacing: -1px;
		}
	}
}

.audio-samples {
	&__title {
		font-size: 25px;
		font-weight: 600;
		line-height: 1.3;
		color: #f3f3f3;
		margin: 0 auto 35px;
		text-align: center;
		letter-spacing: 0.76px;
		padding-top: 50px;

		@media (min-width: 768px) {
			letter-spacing: 1.25px;
			font-size: 30px;
			margin-bottom: 35px;
		}
	}
}

.audio-samples-list {
	display: flex;
	justify-content: center;
	align-content: center;
	flex-wrap: wrap;
	@include reset-list-styles;
	margin: 0 auto 135px;
	-moz-column-gap: 16px;
	column-gap: 16px;
	gap: 16px;
	max-width: 345px;

	@media (min-width: 768px) {
		max-width: 700px;
		justify-content: center;
		gap: 20px;
	}

	&__item {
		cursor: pointer;
		width: 47%;
		max-width: 125px;
		border-radius: 12px;
		border: 1px solid rgba(224, 229, 255, 1);
		padding: 18px 16px 23px 15px;
		display: flex;
		flex-direction: column;
		align-items: center;
		color: #ffffff;
		justify-content: space-between;
		gap: 10px;
		text-align: center;
		cursor: pointer;
		background: #050921;

		&--link {
			position: relative;

			a {
				display: block;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				opacity: 0;
				font-size: 0;
			}
		}

		@media (max-width: 361px) {
			width: 35%;
			max-width: 115px;
		}

		&::after {
			content: '';
			display: block;
			width: 40px;
			height: 40px;
			background: url('/images/icon-play-circle.png') no-repeat center center;
			background-size: contain;
			z-index: 3;
		}

		&:nth-child(10n + 1),
		&:nth-child(10n + 2),
		&:nth-child(10n + 3),
		&:nth-child(10n + 4),
		&:nth-child(10n + 5),
		&:nth-child(10n + 6),
		&:nth-child(10n + 7),
		&:nth-child(10n + 8),
		&:nth-child(10n + 9),
		&:nth-child(10n + 10) {
			position: relative;
			background-size: cover;
			background-position: center center;
		}

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(1, 7, 38, 0.8);
			z-index: 1;
			border-radius: inherit;
		}

		&[data-target$='love'] {
			background-image: url('/images/catalog-audio-samples/love.jpg');
		}

		&[data-target$='career'] {
			background-image: url('/images/catalog-audio-samples/career.jpg');
		}

		&[data-target$='confidence'] {
			background-image: url('/images/catalog-audio-samples/confidence.jpg');
		}

		&[data-target$='energy'] {
			background-image: url('/images/catalog-audio-samples/energy.jpg');
		}

		&[data-target$='goals'] {
			background-image: url('/images/catalog-audio-samples/goals.jpg');
		}

		&[data-target$='health'] {
			background-image: url('/images/catalog-audio-samples/health.jpg');
		}

		&[data-target$='mindfulness'] {
			background-image: url('/images/catalog-audio-samples/mindfulness.jpg');
		}

		&[data-target$='wealth'] {
			background-image: url('/images/catalog-audio-samples/wealth.jpg');
		}

		&[data-target$='weight-loss'] {
			background-image: url('/images/catalog-audio-samples/weight-loss.jpg');
		}

		&[data-target$='motivation'] {
			background-image: url('/images/catalog-audio-samples/motivation.jpg');
			background-position: 65% center;
		}

		&[data-target$='happiness'] {
			background-image: url('/images/catalog-audio-samples/happiness.jpg');
			background-position: 15% center;
		}

		&[data-target$='addiction'] {
			background-image: url('/images/catalog-audio-samples/addiction.jpg');
			background-position: right center;
		}

		&[data-target$='custom-goal'] {
			background-image: url('/images/catalog-audio-samples/custom-goal.jpg');

			&::before {
				background-color: rgba(83, 24, 180, 0.7);
			}
		}

		* {
			position: relative;
			z-index: 2;
		}
	}

	&__title {
		font-weight: 400;
		font-size: 16px;
		line-height: 1;
		margin: 0 auto 7px;
		letter-spacing: 1px;
		color: #ffffff;

		@media (min-width: 768px) {
			font-size: 18px;
		}
	}
}

.personalized-session-description {
	margin-bottom: 95px;
}

@import 'components/modal.scss';
@import 'components/features-list.scss';
@import 'components/people-quotes.scss';
@import 'components/people-stories.scss';
@import 'components/common-questions.scss';
@import 'components/guarantee-block.scss';
@import 'components/text-blocks.scss';
@import 'components/quiz.scss';
@import 'components/modal-audio-sample.scss';
@import 'components/video-players.scss';
